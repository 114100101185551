import { Action, createReducer, on } from '@ngrx/store';
import { checkEmpData, updateEmplyeeStatus, fetchEmployeeData, fetchEmployeeFail, fetchEmployeeSuccess }
    from './employee.action';
import { Employee } from './employee.model';
import moment from 'moment';



export interface EmployeeState {
    employee: Employee[]
}

export const initialState: EmployeeState = {
    employee: []
};

export const EmployeeReducer = createReducer(
    initialState,
    on(fetchEmployeeSuccess, (state, { employee }) => {
        //console.log("employee data " , employee);
        // 

        const currentUserString = localStorage.getItem('currentUser');
        let currentUser = currentUserString ? JSON.parse(currentUserString) : null;

        let onlineEmp = []; let offlineEmp = [];
        if (currentUser && currentUser.employeeId) {
            employee.forEach((item: any) => {

                if (currentUser.employeeId != item.employeeId) {
                    if (item.workStatus == 'online') {
                        item.lastSeenOn = '';
                        onlineEmp.push(item);

                    } else {
                        item.lastSeenOn = moment(item.lastSeenOn).utcOffset('+05:30').format('DD/MM/YYYY, hh:mm A');
                        offlineEmp.push(item);

                    }
                }
            });
        } else {
            employee.forEach((item: any) => {

                if (item.workStatus == 'online') {
                    item.lastSeenOn = '';
                    onlineEmp.push(item);

                } else {
                    item.lastSeenOn = moment(item.lastSeenOn).utcOffset('+05:30').format('DD/MM/YYYY, hh:mm A');
                    offlineEmp.push(item);

                }


            });
        }
        return { employee: [...onlineEmp, ...offlineEmp] };
    }),
    on(checkEmpData, (state) => {
        return { ...state };
    }),
    on(fetchEmployeeFail, (state, { error }) => {
        return { ...state };
    }),
    on(updateEmplyeeStatus, (state, { employeeId, workStatus }) => {
        const indexFound = state.employee.findIndex(
            (item: Employee) => item.employeeId === employeeId
        );

        if (indexFound === -1) {
            return state;
        }

        const updatedEmployee = { ...state.employee[indexFound] };
        updatedEmployee.workStatus = workStatus;
        updatedEmployee.lastSeenOn =
            workStatus === 'online'
                ? ''
                : moment().format('DD/MM/YYYY, hh:mm A');
        const updatedEmployees = [...state.employee];
        updatedEmployees.splice(indexFound, 1);
        if (workStatus === 'online') {
            updatedEmployees.unshift(updatedEmployee);
        } else if (workStatus === 'offline') {
            updatedEmployees.push(updatedEmployee);
        } else {
            updatedEmployees.splice(indexFound, 0, updatedEmployee);
        }
        return {
            ...state,
            employee: updatedEmployees,
        };
    })

);

export function reducer(state: EmployeeState | undefined, action: Action) {
    return EmployeeReducer(state, action);
}
