import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { changesLayout } from "src/app/store/layouts/layout.actions";
import { getLayoutMode } from "src/app/store/layouts/layout.selector";
import { RootReducerState } from "src/app/store";
import { getUser } from "src/app/store/Authentication/authentication-selector";
import { IApiResponse } from "src/app/services/api/models";
import { HttpServiceNode } from "src/app/services/api/api.service";
import { NotificationService } from "src/app/pages/notify-module/notification.service";
import { PbxDiallerService } from "src/app/core/services/pbx-dialling.service";
import { SocketService } from "src/app/services/api/common-services/socket.service";
import { checkEmpData, fetchEmployeeData, updateEmplyeeStatus } from "src/app/store/employee/employee.action";
import Swal from 'sweetalert2';
import { PesonalChatSocketService } from "src/app/services/api/common-services/personalchat.socket.service";
import { FcmService } from "src/app/services/api/fcm.service";
//import { updateNotification } from "src/app/store/chatNotification/chat-notification.action";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/api/common-services/common.service";
import { RecentChatEmployee } from "src/app/pages/chat/chat.model";
import { addNofificationChatEmployeeOnLoadTime, changeChatEmployeeStatus, fetchChatEmployees } from "src/app/store/chatEmployee/chat-employee.action";
import { FirebaseMessage } from "src/app/store/chatNotification/chat-notification.model";
import { insertNotification } from "src/app/store/chatNotification/chat-notification.action";
import { ManageCompanyApiService } from "src/app/services/api/manage.company.api.service";
import { SharedMethodsService } from "src/app/services/api/common-services/shared-methods.service";
import { WebSocketWhatsaapService } from "src/app/pages/lead/lead-grid/whatsaap-chat.service";






@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {
  mode: any;
  totalNotifications: number = 0;
  totalNotifications1: any;
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  layout: string;
  dataLayout$: Observable<string>;
  user$: Subscription;
  phone: any;
  logo: string | null = null;
  employeeState$;
  // Define layoutMode as a property
  employeeData: any = null;
  statusSelectObj = [
    { name: 'Online', val: 'online' },
    { name: 'Offline', val: 'offline' },
    { name: 'On Break', val: 'onBreak' },
    { name: 'On Leave', val: 'onLeave' },
  ];
  currentUserStatus: string = 'online';
  currentUserStatusName: string = 'Online';
  statusClass = {
    online: 'text-success',
    offline: 'text-danger',
    onBreak: 'text-info',
    onLeave: 'text-warning'
  };
  socketId = '';
  statusSelectedName = {
    online: 'Online',
    offline: 'Offline',
    onBreak: 'On Break',
    onLeave: 'On Leave'
  }



  constructor(
    @Inject(DOCUMENT) private document: any,
    private _apiService: HttpServiceNode,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private notificationService: NotificationService,
    public store: Store<RootReducerState>,
    private _pbxDiallerService: PbxDiallerService,
    private _socketClient: SocketService,
    private _chatSocket: PesonalChatSocketService,
    private _toasterService: ToastrService,
    private commonService: CommonService,
    private _companyApi: ManageCompanyApiService,
    private sharedService: SharedMethodsService,
    private webSocketService:WebSocketWhatsaapService
  ) {
    this.employeeData = JSON.parse(localStorage.getItem("currentUser"));
    this.webSocketService.emitJoinleadLivechat(
      this.employeeData.companyId,
      this.employeeData.employeeId
    );
    const employeeData = JSON.parse(localStorage.getItem("currentUser"));
		this.webSocketService.emitJoinLivechat(
		  employeeData.companyId,
		  employeeData.employeeId
		);
    const currentUser = localStorage.getItem("currentUser");
    const user = JSON.parse(currentUser);
    const companyId = user.companyId;

    this.getCompanyById(companyId);

    this.employeeData = JSON.parse(localStorage.getItem('currentUser'));
    // console.log("emp ", this.employeeData)

    this.getCountLevel();

  }

  listLang: any = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();


  ngOnInit() {
    // this.initialAppState = initialState;
    this.currentUserStatusName  = this.statusSelectedName[ localStorage.getItem('userStatus') ?? 'online' ];

    this.sharedService.logoUrl.subscribe(res => {
      this.logo = res;
    })

    this.store.select("layout").subscribe((data) => {
      this.theme = data.DATA_LAYOUT;
    });
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.user$ = this.store.select(getUser).subscribe((data) => {
      //console.log(data, "data");
    });
    this.notificationService.notificationCount$.subscribe((count) => {

      this.totalNotifications1 = count;
      this.getCountLevel();
    });

    const callingDialler = document.getElementById('callingdialler');
    if (this.employeeData.ivr_signature) {
      this._pbxDiallerService.checkPBK();
      callingDialler.style.display = 'block';
    } else {
      callingDialler.style.display = 'none';
    }

 

    

    this._chatSocket.setConnection();

    // this.fcmService.listenForMessages().subscribe(
    //   (message) => {

    //     //this.messagesNotifications.push(message) ;
    //     this.store.dispatch(updateNotification({ notification: message }));
    //     this._toasterService.info(
    //       message.data.content,
    //       message.data.firstName
    //     );
    //     //this.store<ChatNoti>.
    //   },
    //   (error) => {
    //     console.error('Error while listening for notifications: ................................................................', error);
    //   });

    this._chatSocket.emit('get-chat-list', { userId: this.employeeData.employeeId, companyId: this.employeeData.companyId });
    this._chatSocket.emit('message-count', { employeeId: this.employeeData.employeeId, companyId: this.employeeData.companyId });

    this.commonService.updateEmployeeData(this.employeeData);
    this._chatSocket.listen('receive-chat-list').subscribe((item: RecentChatEmployee[]) => {
      this.store.dispatch(fetchChatEmployees({ allchatEmployee: item }));
    });
    this._chatSocket.listen('update-data').subscribe((item: any) => {
      console.log("someone chnage status" );
      try {
        if (item) {
          if (item.employeeId != this.employeeData.employeeId) {
            this.store.dispatch(updateEmplyeeStatus({ employeeId: item.employeeId, workStatus: item.workStatus }));
            this.store.dispatch(changeChatEmployeeStatus({ chatEmployeeId: item.employeeId, status: item.workStatus }));
          } else {
            this.store.dispatch(updateEmplyeeStatus({ employeeId: item.employeeId, workStatus: item.workStatus }));
          }
        } 
      } catch (err) {
        console.log("error ", err)
      }
    });
    this._chatSocket.listen('count-message').subscribe((item: any) => {
      let notificationModel: FirebaseMessage[] = item.map((msg: any) => {
        let obj: FirebaseMessage = {
          from: '',
          data: {
            content: msg.content,
            contentType: msg.contentType,
            firstName: msg.employeeName,
            senderId: msg.employeeId,
            profilePicUrl: msg.profilePicUrl,
            roomId: msg.roomId,
            body: '',
            title: '',
            type: msg.type,
          },
          count: msg.count
        }
        return obj;
      });
      this.store.dispatch(insertNotification({ notification: notificationModel }));
      this.store.dispatch(addNofificationChatEmployeeOnLoadTime({ notificationArr: notificationModel }));
    });
    this.store.dispatch(fetchEmployeeData());

  }


  updateCurrentStatus(status: { name: string, val: string }): void {
    localStorage.setItem('userStatus', status.val);
    this.currentUserStatus = status.val;
    this.currentUserStatusName = status.name;
    this._socketClient.userStatus$.next(status.val);
    this._chatSocket.emit('update-status', { employeeId: this.employeeData.employeeId, status: this.currentUserStatus, companyId: this.employeeData.companyId });
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }


  /**
   * Logout the user
   */
  logout() {
    //let sameBrowserCheck =  localStorage.getItem('abc');
    // check socket is same when we active on other pages on same web
    let deviceId = localStorage.getItem('deviceId') ?? '';
    let endpoint = `employee/logout/${this.employeeData.employeeId}`;
    let deviceToken = localStorage.getItem('deviceToken');
    let payload = {
      deviceId, deviceToken
    }
    this._apiService.put(endpoint, payload).subscribe({
      next: (response: any) => {
        if (environment.defaultauth === "firebase") {
          this.authService.logout();
        } else {
          this.authFackservice.logout();
        }
        this.router.navigate(["/auth/login"]);
      },
      error: (err) => {
        if (environment.defaultauth === "firebase") {
          this.authService.logout();
        } else {
          this.authFackservice.logout();
        }
        this.router.navigate(["/auth/login"]);
        console.log("error ", err);
        // console.error('Error fetching services:', err);
      }
    });
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLayout(layoutMode: string) {
    this.theme = layoutMode;
    this.store.dispatch(changesLayout({ layoutMode }));
    this.store.select(getLayoutMode).subscribe((layout) => {
      document.documentElement.setAttribute("data-layout", layout);
    });
  }
  gotoNotify() {
    this.router.navigate(["/notification"]);
  }
  getCountLevel(): void {

    let currentUser = localStorage.getItem("currentUser");
    let empId = currentUser ? JSON.parse(currentUser).employeeId : null;

    const url = "notification/notification/counts";
    const formData = {
      employeeId: empId,
    };

    this._apiService.get(url, formData).subscribe({
      next: (response: any) => {

        if (response) {
          this.totalNotifications = response.total;
        }
      },
      error: (err: IApiResponse<any>) => {
        console.error("Error fetching notification counts", err);
      },
    });
  }

  openProfile() {
    this.router.navigate(["company-profile"]);
  }

  getCompanyById(companyId: string) {
    const endpoint = `company/get/id/${companyId}`;
    this._companyApi.get(endpoint).subscribe({
      next: (response: any) => {
        this.logo = response?.logoUrl || 'N/A';
      },
      error: (err: IApiResponse<any>) => {
        console.error('Error fetching company details:', err);
      },
    });
  }

  ngOnDestroy(): void {

    // // this._socketClient.userStatus$.unsubscribe();
  }
}
