import { Injectable, signal } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Receiver } from 'src/app/pages/chat/chat.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectEmployeeById } from 'src/app/store/chatEmployee/chat-employee.selector';
import { chatEmployee } from 'src/app/store/chatEmployee/chat-employee.model';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PesonalChatSocketService {
  private socket: Socket;
  employeeId: string;
  currentUser: any;
  receiverInfo: Receiver;
  userStatus: string | null = '';
  private basePersonalChatSocketUrl: string;

  constructor(private router: Router, private store: Store) {
    this.basePersonalChatSocketUrl = environment.baseUrlChat;
  }
  setConnection(): void {
    const currentUserString = localStorage.getItem('currentUser');
    const currentUser: any = currentUserString ? JSON.parse(currentUserString) : null;
    this.userStatus = localStorage.getItem('userStatus');

    this.currentUser = currentUser;
    if (!this.userStatus || (this.userStatus == 'offline')) {
      //console.log("user status set");
      this.userStatus = 'online';
    }
    this.disconnect();

    this.socket = io(this.basePersonalChatSocketUrl, {
      transports: ['websocket']
    });

    this.socket.on('connect', () => {
      console.log("socket connected successfully");
      if (
        typeof this.userStatus === 'string' &&
        ['online', 'offline', 'onBreak', 'onLeave'].includes(this.userStatus) &&
        this.currentUser?.employeeId
      ) {
        this.emit('update-status', {
          employeeId: this.currentUser?.employeeId,
          status: this.userStatus,
          companyId: this.currentUser?.companyId
        });
        // console.log("user status change ");
      }
      this.emit('join-user', { userId: this.currentUser.employeeId, companyId: this.currentUser.companyId });
    });

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection Error:', error);
    });
    this.socket.on('error', (error: any) => {
      console.error('Socket Error:', error);
    });

    this.socket.on('disconnect', () => {
      // console.log('Disconnected from the server 1');
    });
  }
  emit(event: string, data: any): void {
    this.socket.emit(event, data);
  }
  listen(event: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(event, (data) => {
        subscriber.next(data);
      });
      return () => {
        this.socket.off(event);
      };
    });
  }

  disconnect(): void {

    if (this.socket) {
      //console.log('s2 destroyed');

      this.socket.disconnect();

    } else {
      //console.log('s2 not found ');

    }
  }
  setReceiver(data: any): void {
    let receiver: Receiver = {
      employeeId: data.senderId,
      roomId: data.roomId,
      employeeName: data.firstName,
      type: data.type,
      profilePicUrl: data.profilePicUrl
    }
    if (data.type != 'group') {
      this.store
        .select(selectEmployeeById(data.senderId))
        .pipe(take(1))
        .subscribe((employee: chatEmployee | undefined) => {
          if (employee) {
            receiver.status = employee.participant.status;
          } else {
            receiver.status = 'offline';
          }
          this.receiverInfo = receiver;
          this.router.navigate(['/chat']);
        });
    }

    this.receiverInfo = receiver;
    this.router.navigate(['/chat']);
  }
  forWardToPage(emp: any): void {
    this.store
      .select(selectEmployeeById(emp.employeeId))
      .pipe(take(1))
      .subscribe((employee: chatEmployee | undefined) => {
        const receiver: Receiver = {
          employeeId: employee ? employee.participant.participantId : emp.employeeId,
          roomId: employee ? employee.roomId : 'initiate',
          employeeName: `${employee ? employee.participant.firstName : emp.firstName} ${employee ? employee.participant.lastName : emp.lastName}`,
          type: 'personal',
          profilePicUrl: employee ? employee.participant.profilePicUrl : emp.profilePicUrl,
          status: employee ? employee.participant.status : 'offline',
        };

        this.receiverInfo = receiver;
        this.router.navigate(['/chat']);
      });
  }

  setReceiverByNotificationBackground(data: any): void {
    let receiver: Receiver = data;
    this.receiverInfo = receiver;
  }
  getReceiver(): Receiver {
    return this.receiverInfo;
  }
  leaveRoom(roomId: string): void {
     console.log("LR" , roomId)
    this.emit('leave-room', { roomId: roomId, userId: this.currentUser.employeeId });
  }
}
