import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { IApiResponse } from './models';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ManageCompanyApiService {

  private baseUrl: string;
  private headers: HttpHeaders;

  constructor(private _toastrService: ToastrService,
    private _http: HttpClient,
  ) { 
    this.headers = new HttpHeaders({
     
      // 'Access-Control-Allow-Headers': '*',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      // 'Content-Type': 'application/json'
    });
    if (!this.baseUrl) {
      this.baseUrl = environment.baseUrlCompany;
    }
  }
  downloadAudio(id : string ): Observable<Blob> {
      let apiUrl = this.baseUrl + `call-recording/download/${id}`;
       return this._http.get(apiUrl  , { responseType: 'blob' });
  }



  set _baseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get<IResponse>(
    slug: string,
    params: any = {},
    showToast: boolean = true
  ): Observable<IApiResponse<IResponse | any>> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .get<IApiResponse<IResponse | any>>(`${this.baseUrl}${slug}`, {
          headers: this.headers,
          params: params,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<IResponse | any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<IResponse> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  post(
    endpoint: string,
    payload: any,
    
    showToast: boolean = true
  ): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .post<IApiResponse<any>>(`${this.baseUrl}${endpoint}`, payload, {
          headers:this.headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            //console.log(response);
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }
  put(
    endpoint: string,
    payload: any,
    showToast: boolean = true
  ): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .put<IApiResponse<any>>(`${this.baseUrl}${endpoint}`, payload, {
          headers: this.headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            //console.log(response);
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  delete(endpoint: string, showToast: boolean = true): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .delete(`${this.baseUrl}${endpoint}`, { headers: this.headers })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error in delete api => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  // for list type response only
  getList<IResponse>(
    endpoint,
    payload: any = {},
    showToast: boolean = true
  ): Observable<IApiResponse<IResponse | any>> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .post<IApiResponse<IResponse | any>>(
          `${this.baseUrl}${endpoint}`,
          payload,
          { headers: this.headers }
        )
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<IResponse | any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<IResponse> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  getListForRawURL<IResponse>(
    endpoint,
    payload: any = {},
    showToast: boolean = true
  ): Observable<IApiResponse<IResponse | any>> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .post<IApiResponse<IResponse | any>>(`${endpoint}`, payload, {
          headers: this.headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<IResponse | any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<IResponse> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  uploadFiles(formData: FormData, showToast: boolean = true): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      const headers = new HttpHeaders({
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      });

      // headers.append('Content-Type', 'multipart/form-data')
      this._http
        .post<IApiResponse<any>>(`${this.baseUrl}Common/UploadFile`, formData, {
          headers: headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  private sendData<IResponse>(
    observer: Subscriber<any>,
    result: IApiResponse<IResponse | any>,
    showToast: boolean = true
  ) {
    if (result?.status) {
      if (result.status.toLowerCase() === "ok") {
        const data = result.data ? result.data : result;
        observer.next(data);
      } else {
        let title = "Error";
        if (!result || (result && !result.status)) {
          title = "Internal Server issue";
          result = {
            status: "Error",
            error: "Something went wrong",
            errorCode: "500",
          };
        }

        if (showToast) {
          this._toastrService.error(result.message, title);
        }

        observer.error(result);
      }
    } else {
      let title = "Error";
      if (!result || (result && !result.status)) {
        title = "Internal Server issue";
        // result = {
        // 	status: 'Error',
        // 	error: 'Something went wrong',
        // 	errorCode: '500'
        // }
        result["status"] = "Error";
        result["error"] = result["error"] || "Something went wrong";
      }

      if (showToast) {
        this._toastrService.error(result.error, title);
      }

      observer.error(result);
    }

    observer.complete();
  }
  formDataPost(endpoint: string, payload: any) {
    const formData = new FormData();
    for (let key in payload) {
      
      formData.append(key, payload[key]);
    }

    return this._http.post(`${this.baseUrl}${endpoint}`, formData);
  }
  private setHeaders(): HttpHeaders {
    const headersConfig = {
      "Content-Type": "multipart/form-data",
      // Accept: 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods':'GET,POST, PUT, OPTIONS',
      // 'Access-Control-Allow-Headers':'Content-Type',
    };
    return new HttpHeaders(headersConfig);
  }
}
