import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../../core/services/token-storage.service';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import Swal from 'sweetalert2';

// const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
const TOKEN_HEADER_KEY = 'x-access-token';   // for Node.js Express back-end

const TOKEN_COMPANY_KEY = 'x-company-id';
const DEVICE_ID = 'x-device-id';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private deviceId = '';
  constructor(
    private token: TokenStorageService,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
  ) {
    //console.log(" .....................................  intercepter loaded ");
    this.deviceId = localStorage.getItem('deviceId');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      // for Spring Boot back-end
      // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

      // for Node.js Express back-end
      const currentUserString = localStorage.getItem('currentUser');
      if (currentUserString) {
        const parsedData = JSON.parse(currentUserString)
        authReq = req.clone(
          {
            headers: req.headers
              .set(DEVICE_ID, this.deviceId)
              .set(TOKEN_HEADER_KEY, token)
              .set(TOKEN_COMPANY_KEY, parsedData.companyId)
          }
        );
      }

    }
   // return next.handle(authReq);
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401) || (error.status === 403)) {
          if (environment.defaultauth === "firebase") {
            this.authService.logout();
          } else {
            this.authFackservice.logout();
          }
          Swal.fire('Auth Error' , 'someone login from same credention , please login again' , 'error');
          this.router.navigate(["/auth/login"]);
        }
        return  throwError(()=>error);
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];