import { Injectable, signal } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {

  employeeId: string;
  userStatus  =  '';
  userStatus$  =  new Subject() ;
  socketId$ =  new BehaviorSubject('');
  statusEnum  = [ 'online' , 'offline' , 'onBreak' , 'onLeave' ];
  currentUser : any ;
  constructor() {
   
  }
}
