import { createAction, props } from '@ngrx/store';
import  {   chatEmployee  }   from './chat-employee.model' ;
import { FirebaseMessage } from '../chatNotification/chat-notification.model';


export const fetchChatEmployees = createAction('[Data] fetch  chatEmployee' , props<{ allchatEmployee: chatEmployee[]  }>());
export const addChatEmployee = createAction('[Data] add  chatEmployee' , props<{ chatEmployee: chatEmployee }>());
export const changeChatEmployeeStatus = createAction('[Data] change status   chatEmployee' , props<{ chatEmployeeId: string , status : string   }>());
export const addNofificationChatEmployee = createAction('[Data] add notification  in  chatEmployee' , props<{ notification:  FirebaseMessage   }>());
export const addNofificationChatEmployeeOnLoadTime = createAction('[Data] add all notification in   chatEmployee' , props<{ notificationArr:  FirebaseMessage[]  }>());
export const removeNofificationChatEmployee = createAction('[Data] remove  notification from    chatEmployee' , props<{ roomId : string    }>());
export const addMyLastChatAndTime = createAction('[Data] add message in    chatEmployee' , props<{ roomId  : string   , content :  string  , contentType: string   }>());





