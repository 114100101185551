import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedMethodsService {
    public serviceInterestId: Observable<string | null>;
    public visaTypeId: Observable<string | null>;
    private serviceInterestSubject: BehaviorSubject<string | null>;
    private visaTypeSubject: BehaviorSubject<string | null>;
    private logoUploadSubject: BehaviorSubject<string | null>;
    public logoUrl: Observable<string | null>;


    constructor() {
        this.serviceInterestSubject = new BehaviorSubject<string | null>(null);
        this.serviceInterestId = this.serviceInterestSubject.asObservable();
        this.visaTypeSubject = new BehaviorSubject<string | null>(null);
        this.visaTypeId = this.visaTypeSubject.asObservable();
        this.logoUploadSubject = new BehaviorSubject<string | null>(null);
        this.logoUrl =  this.logoUploadSubject.asObservable();
    }

    requiredFormValidation(formName: FormGroup, formControlName: string, isFormSubmitted: boolean): boolean {
        return (formName.controls[formControlName].hasError('required')
            && (formName.controls[formControlName].dirty
                || formName.controls[formControlName].touched)) ||
            (formName.controls[formControlName].hasError('required') && isFormSubmitted);
    }
    patternFormValidation(formName: FormGroup, formControlName: string, isFormSubmitted: boolean): boolean {
        return ((formName.controls[formControlName].hasError('pattern')
            && formName.controls[formControlName].touched) ||
            (!formName.controls[formControlName].hasError('required') && isFormSubmitted));
    }

    markFormGroupTouched(formGroup: FormGroup): void {
        Object.values(formGroup.controls).forEach((control) => {
            control.markAsTouched();

            if (control instanceof FormGroup) {
                this.markFormGroupTouched(control);
            }
        });
    }

    setServiceInterestId(serviceInterestId: string | null): void {
        this.serviceInterestSubject.next(serviceInterestId);
    }

    logoUpload(logoUrl: string){
        this.logoUploadSubject.next(logoUrl);
    }


    setVisaTypeId(visaTypeId: string | null): void {
        this.visaTypeSubject.next(visaTypeId);
    }
    public GetDateTimeDifference(datetime1, datetime2): string {
        if (datetime1 && datetime2) {
            const createdDate = new Date(datetime1);
            const convertedDate = new Date(datetime2);

            const timeDifference = Math.abs(convertedDate.getTime() - createdDate.getTime());
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 0) {
                return `${days} day${days > 1 ? 's' : ''} 
                ${hours ? hours % 24 : ''} hr${hours % 24 > 1 ? 's' : ''}
                ${minutes ? minutes % 60 : ''} min${minutes % 60 > 1 ? 's' : ''}
                ${seconds % 60} sec${seconds % 60 > 1 ? 's' : ''}`;
            }
            if (hours > 0) {
                return `${this.pad(hours)}:${minutes ? this.pad(minutes % 60) : ''}:${this.pad(seconds % 60)}`;
            }
            if (minutes > 0) {
                return `00:${this.pad(minutes)}:${this.pad(seconds % 60)}`;
            }
            if (seconds > 0) {
                return `00:00:${this.pad(seconds)}`;
            }
        }
        else {
            return '-';
        }
    }
    pad(n: number): string {
        return (n < 10 ? '0' : '') + n;
    }

    preventSpaceAndAllowNewLineOnKeyPress(event: KeyboardEvent, formGroup: FormGroup, patchValueField: any): void {
        const target = event.target as HTMLTextAreaElement;
        const key = event.key;
        const cursorPosition = target.selectionStart;
        const value = target.value;

        // Prevent double spaces
        if (key === ' ' && value[cursorPosition - 1] === ' ') {
            event.preventDefault();
        }

        // Allow new lines but collapse multiple spaces
        // Split by new lines and then replace multiple spaces in each line
        const lines = value.split('\n');
        const processedValue = lines.map(line => line.replace(/\s{2,}/g, ' ')).join('\n');

        const patchObject: { [key: string]: any } = {};
        patchObject[patchValueField] = processedValue;

        // Patch the form value
        formGroup.patchValue(patchObject);
    }
}
