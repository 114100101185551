import { Injectable, signal, WritableSignal } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group, Receiver } from 'src/app/pages/chat/chat.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectEmployeeById } from 'src/app/store/chatEmployee/chat-employee.selector';
import { chatEmployee } from 'src/app/store/chatEmployee/chat-employee.model';
import { take } from 'rxjs';
import moment from 'moment';
import { fetchEmployeeData, fetchEmployeeSuccess } from 'src/app/store/employee/employee.action';

@Injectable({
  providedIn: 'root',
})
export class PesonalChatSocketService {
  private socket: Socket;
  employeeId: string;
  currentUser: any;
  receiverInfo: Receiver;
  userStatus: string | null = '';
  private basePersonalChatSocketUrl: string;
  //public groupList: WritableSignal<Group[]> = signal([]);
  public groupList = new BehaviorSubject<Group[]>([]);
  public groupListNotification = new BehaviorSubject<Group[]>([]);




  constructor(private router: Router, private store: Store) {
    this.basePersonalChatSocketUrl = environment.baseUrlChat;
  }
  setConnection(): void {
    const currentUserString = localStorage.getItem('currentUser');
    const currentUser: any = currentUserString ? JSON.parse(currentUserString) : null;
    this.userStatus = localStorage.getItem('userStatus');

    this.currentUser = currentUser;
    if (!this.userStatus || (this.userStatus == 'offline')) {
      //console.log("user status set");
      this.userStatus = 'online';
    }
    this.disconnect();

    this.socket = io(this.basePersonalChatSocketUrl, {
      transports: ['websocket']
    });

    this.socket.on('connect', () => { 
      console.log("socket connected successfully");
      if (
        typeof this.userStatus === 'string' &&
        ['online', 'offline', 'onBreak', 'onLeave'].includes(this.userStatus) &&
        this.currentUser?.employeeId
      ) {
        this.emit('update-status', {
          employeeId: this.currentUser?.employeeId,
          status: this.userStatus,
          companyId: this.currentUser?.companyId
        });
        // console.log("user status change ");
      }
      this.emit('join-user', { userId: this.currentUser.employeeId, companyId: this.currentUser.companyId });
      this.store.dispatch(fetchEmployeeData());
    });

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection Error:', error);
    });
    this.socket.on('error', (error: any) => {
      console.error('Socket Error:', error);
    });

    this.socket.on('disconnect', () => {
      console.log('socket Disconnected  ');
    });
  }
  emit(event: string, data: any): void {
    this.socket.emit(event, data);
  }
  listen(event: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(event, (data) => {
        subscriber.next(data);
      });
      return () => {
        this.socket.off(event);
      };
    });
  }

  disconnect(): void {

    if (this.socket) {
      //console.log('s2 destroyed');
      this.socket.disconnect();

    } else {
      //console.log('s2 not found ');

    }
  }
  setReceiver(data: any): void {
    let receiver: Receiver = {
      employeeId: data.senderId,
      roomId: data.roomId,
      employeeName: data.firstName,
      type: data.type,
      profilePicUrl: data.profilePicUrl
    }
    if (data.type != 'group') {
      this.store
        .select(selectEmployeeById(data.senderId))
        .pipe(take(1))
        .subscribe((employee: chatEmployee | undefined) => {
          if (employee) {
            receiver.status = employee.participant.status;
          } else {
            receiver.status = 'offline';
          }
          this.receiverInfo = receiver;
          this.router.navigate(['/chat']);
        });
    }

    this.receiverInfo = receiver;
    this.router.navigate(['/chat']);
  }
  forWardToPage(emp: any): void {
    this.store
      .select(selectEmployeeById(emp.employeeId))
      .pipe(take(1))
      .subscribe((employee: chatEmployee | undefined) => {
        const receiver: Receiver = {
          employeeId: employee ? employee.participant.participantId : emp.employeeId,
          roomId: employee ? employee.roomId : 'initiate',
          employeeName: `${employee ? employee.participant.firstName : emp.firstName} ${employee ? employee.participant.lastName : emp.lastName}`,
          type: 'personal',
          profilePicUrl: employee ? employee.participant.profilePicUrl : emp.profilePicUrl,
          status: employee ? employee.participant.status : 'offline',
        };

        this.receiverInfo = receiver;
        this.router.navigate(['/chat']);
      });
  }

  setReceiverByNotificationBackground(data: any): void {
    let receiver: Receiver = data;
    this.receiverInfo = receiver;
  }
  getReceiver(): Receiver {
    return this.receiverInfo;
  }
  leaveRoom(roomId: string): void {

    this.emit('leave-room', { roomId: roomId, userId: this.currentUser.employeeId });
  }

  updateGroupList(groups: Group[]): void {
    groups = groups.filter((gp) => gp.groupId && gp.groupName && gp.roomId)
    this.groupList.next(groups);
    this.updateNotification();
  }

  updateGroupNotificationInStart(
    unreadMessageCount: Record<string, number>
  ): void {
    let updatedGroups = this.groupList.getValue().map((item) => {
      if (unreadMessageCount[item.roomId]) {
        item.unreadMessage = unreadMessageCount[item.roomId];
      }
      if (item.lastMessageTime) {
        if (item.lastMessageType == 'text') {
          if (item.lastMessage.length > 53) {
            item.lastMessage = item.lastMessage.slice(0, 48)
          }
        } else {
          if (item.lastMessageType == 'image') {
            item.lastMessage = 'image ...';
          }
          else if (item.lastMessageType == 'link') {
            item.lastMessage = 'link ...';
          }
          else if (item.lastMessageType == 'emoji') {
          }
          else {
            item.lastMessage = 'file ...'
          }
        }
        item.lastMessageTime = item.lastMessageTime
          ? moment(item.lastMessageTime).isSame(moment(), 'day')
            ? moment(item.lastMessageTime).format('hh:mm A')
            : moment(item.lastMessageTime).format('DD/MM/YYYY')
          : '';
      }
      return item;
    });
    if (updatedGroups && Array.isArray(updatedGroups)) {
      updatedGroups.sort((a, b) => (b.unreadMessage ?? 0) - (a.unreadMessage ?? 0));
    }

    this.groupList.next(updatedGroups);
    this.updateNotification();
  }

  updateGroupNotification(lastMessage: string, roomId: string, contentType: string = 'text'): void {

    let i;
    let updatedGroups = this.groupList.getValue().map((item, index) => {
      if (item.roomId === roomId) {
        item.lastMessageType = contentType;
        item.unreadMessage = (item.unreadMessage || 0) + 1;
        item.lastMessage = lastMessage;
        i = index;
        item.lastMessageTime = moment().format('hh:mm A');

        if (item.lastMessageType == 'text') {
          if (item.lastMessage.length > 53) {
            item.lastMessage = item.lastMessage.slice(0, 48)
          }
        } else {
          if (item.lastMessageType == 'image') {
            item.lastMessage = 'image ...';
          }
          else if (item.lastMessageType == 'link') {
            item.lastMessage = 'link ...';
          }
          else if (item.lastMessageType == 'emoji') {
          }
          else {
            item.lastMessage = 'file ...'
          }
        }
      }

      return item;
    });
    if (updatedGroups && Array.isArray(updatedGroups)) {

      updatedGroups = [...updatedGroups.splice(i, 1), ...updatedGroups];
    }
    this.groupList.next(updatedGroups);
    this.updateNotification();
  }
  updateMyLastChatTimeInGroupMessage(lastMessage: string, roomId: string, contentType: string = 'text'): void {
    let i;
    let updatedGroups = this.groupList.getValue().map((item, index) => {
      if (item.roomId === roomId) {
        item.lastMessageType = contentType;
        item.unreadMessage = 0;
        item.lastMessage = lastMessage;
        i = index;
        item.lastMessageTime = moment().format('hh:mm A');

        if (item.lastMessageType == 'text') {
          if (item.lastMessage.length > 53) {
            item.lastMessage = item.lastMessage.slice(0, 48)
          }
        } else {
          if (item.lastMessageType == 'image') {
            item.lastMessage = 'image ...';
          }
          else if (item.lastMessageType == 'link') {
            item.lastMessage = 'link ...';
          }
          else if (item.lastMessageType == 'emoji') {
          }
          else {
            item.lastMessage = 'file ...'
          }
        }
      }

      return item;
    });
    if (updatedGroups && Array.isArray(updatedGroups)) {

      updatedGroups = [...updatedGroups.splice(i, 1), ...updatedGroups];
    }
    this.groupList.next(updatedGroups);
    this.updateNotification();
  }

  markAsReadNotification(roomId: string): void {
    const updatedGroups = this.groupList.getValue().map((item) => {
      if (item.roomId === roomId) {
        item.unreadMessage = 0;
      }
      return item;
    });
    this.groupList.next(updatedGroups);
    this.updateNotification();
  }
  private updateNotification(): void {
    const updatedGroups = this.groupList.getValue().filter((item) => item.unreadMessage && item.unreadMessage > 0);
    this.groupListNotification.next(updatedGroups);
  }


  getGroupList(): Observable<Group[]> {
    return this.groupList.asObservable();
  }

  getGroupListNotification(): Observable<Group[]> {
    return this.groupListNotification.asObservable();
  }

}