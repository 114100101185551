import { Component, OnInit } from '@angular/core';
import { FcmService } from './services/api/fcm.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private fcmService: FcmService
  ) {     }
  ngOnInit() {
     let deviceId : string  ;
      deviceId  =  localStorage.getItem('deviceId') ;
     if(!deviceId){
         deviceId =  crypto.randomUUID();
         localStorage.setItem('deviceId' , deviceId)
     }
     //console.log("device id access across multiple tabs "   , deviceId);

    this.fcmService.requestPermission().subscribe(
      (token) => {
        console.log('FCM Token:', token);
        // The token is now registered on the backend
        localStorage.setItem('deviceToken' , token );
      },
      (error) => {
        console.error('Error while requesting permission:', error);
      }
    );
    // Listen for incoming messages
    // this.fcmService.listenForMessages().subscribe((message) => {
    //    //console.log('Message received:', message);
    //   // Handle the message (show a notification, update UI, etc.)
    // });

  
  } 
}