import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
} from "@angular/core";
import MetisMenu from "metismenujs";
import { EventService } from "../../core/services/event.service";
import { Router, NavigationEnd } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { TranslateService } from "@ngx-translate/core";
import { MarketingAutomationApiService } from "src/app/services/api/marketing.automation.api.service";
import { CommunicationService } from "src/app/pages/live-chat/chat/share.service";
// import { link } from 'fs';
import { LeadCommunicationService } from "src/app/pages/lead/lead-grid/lead-service-update-count";
import { WebSocketWhatsaapService } from "src/app/pages/lead/lead-grid/whatsaap-chat.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;

  menuItems: MenuItem[] = [];
  employeeData: any;

  @ViewChild("sideMenu") sideMenu: ElementRef;
  contactCount: any;
  leadDataCount: any;
  currentUser: any;

  constructor(
    private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private httpNodemarketing: MarketingAutomationApiService,
    private communicationService: CommunicationService,
    private LeadCommunicationService:LeadCommunicationService,
    private webSocketService : WebSocketWhatsaapService
  ) {
    this.getAllcontactcount();
    this.getAllLeadCount();
    console.log(
      "..............................................................................................................................."
    );
   
    const currentMenu = localStorage.getItem("sidebarMenu");
    let menubar = currentMenu ? JSON.parse(currentMenu) : null;
    this.menuItems = menubar;
    this.employeeData = JSON.parse(localStorage.getItem("currentUser"));
    if (!this.employeeData) {
    }
    // this._socketClient.setConnection();

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    const currentUserString = localStorage.getItem('currentUser');
		this.currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    // this.initialize();
    this._scrollElement();
    this.communicationService.message$.subscribe((message) => {
      this.getAllcontactcount();
    });
    this.LeadCommunicationService.message$.subscribe((message) => {
      this.getAllLeadCount();
    });

  

    if (
			this.currentUser.roleName === "Admin" ||
			this.currentUser.roleName === "Data Analytics" ||
			this.currentUser.roleName === "Director" ||
			this.currentUser.roleName === "BPO Lead Head"
		  ){
	this.webSocketService.onLiveChatUpdate().subscribe(() => {
		console.log("Triggered live-chat-update event.");
	  this.communicationService.emitMessage('Update count');
		this.showWelcomeNotification();
	  });
  this.webSocketService.onLiveLeadChatUpdate().subscribe(() => {
      console.log("Triggered lead-live-chat-update event.");
      this.LeadCommunicationService.emitMessage('Update count');
      this.getAllLeadCount();
      this.showLeadWelcomeNotification()
    });


  }
  }
  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    const userData = JSON.parse(localStorage.getItem("currentUser"));

    if (userData) {
      this.menuItems = MENU.filter((m) => {
        return MENU;
        // userData.permissions.find(
        //   (p) => p.title == m.label && p.isAllCompleted && p.isChecked
        // );
      });
      // this.menuItems = userData.permissions
      //   .filter((p) => {
      //     return p.isAllCompleted && p.isChecked;
      //   })
      //   .map((p) => {
      //     return { label: p.title };
      //   });
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  getAllcontactcount() {
    const employeeData = JSON.parse(localStorage.getItem("currentUser"));
    const payload = {
      pageNumber: 1,
      pageSize: 1000,
    };

    this.httpNodemarketing.get("contacts/unread-message").subscribe(
      (response) => {
        this.contactCount = response.count;
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
  getAllLeadCount() {
    const employeeData = JSON.parse(localStorage.getItem("currentUser"));
    const payload = {
      employeeId: employeeData.employeeId,
    };

    this.httpNodemarketing
      .post("whatsapp-message/unread/total-count", payload)
      .subscribe(
        (response) => {
          this.leadDataCount = response.count;
        },
        (error) => {
          console.error("API Error:", error);
        }
      );
  }

  showWelcomeNotification() {
		if ('Notification' in window) {
		  // Check if notifications are denied
		  if (Notification.permission === 'denied') {
			alert(
			  'Notifications are blocked in your browser settings. Please enable notifications manually by clicking the lock icon in the address bar.'
			);
			return;
		  }
	  
		  // Check if permission is already granted
		  if (Notification.permission === 'granted') {
			this.displayNotification();
		  } else if (Notification.permission === 'default') {
			// Request notification permission
			Notification.requestPermission().then((permission) => {
			  if (permission === 'granted') {
				this.displayNotification();
			  } else {
				alert(
				  'Notifications are not enabled. Please allow notifications in your browser settings.'
				);
			  }
			});
		  }
		} else {
		  console.warn('Browser does not support notifications.');
		}
	  }
	  
	  displayNotification() {
		const notification = new Notification('VisionWay!', {
		  body: 'New Contact chat message, please check.',
		  icon: 'https://cdn-icons-png.flaticon.com/512/561/561127.png', // Chat icon
		  requireInteraction: true,
		});
	  
		// Optional: Play a sound
		try {
		  const audio = new Audio('https://www.soundjay.com/button/beep-07.wav');
		  audio.play().catch((error) => {
			console.error('Audio playback failed:', error);
		  });
		} catch (error) {
		  console.error('Audio initialization failed:', error);
		}
	  
		// Handle notification click
		notification.onclick = () => {
		  console.log('Notification clicked!');
		  window.open('/live-chat/chat', '_blank'); // Open in a new tab
		  notification.close(); // Close the notification
		};
	  }



    showLeadWelcomeNotification() {
      if ('Notification' in window) {
        // Check if notifications are denied
        if (Notification.permission === 'denied') {
        alert(
          'Notifications are blocked in your browser settings. Please enable notifications manually by clicking the lock icon in the address bar.'
        );
        return;
        }
      
        // Check if permission is already granted
        if (Notification.permission === 'granted') {
        this.displayLeadNotification();
        } else if (Notification.permission === 'default') {
        // Request notification permission
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
          this.displayNotification();
          } else {
          alert(
            'Notifications are not enabled. Please allow notifications in your browser settings.'
          );
          }
        });
        }
      } else {
        console.warn('Browser does not support notifications.');
      }
      }
      
      displayLeadNotification() {
      const notification = new Notification('VisionWay!', {
        body: 'New Lead chat message, please check.',
        icon: 'https://cdn-icons-png.flaticon.com/512/561/561127.png', // Chat icon
        requireInteraction: true,
      });
      
      // Optional: Play a sound
      try {
        const audio = new Audio('https://www.soundjay.com/button/beep-07.wav');
        audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
        });
      } catch (error) {
        console.error('Audio initialization failed:', error);
      }
      
      // Handle notification click
      notification.onclick = () => {
        console.log('Notification clicked!');
        window.open('/lead', '_blank'); // Open in a new tab
        notification.close(); // Close the notification
      };
      }
	  

}
