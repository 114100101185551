import { Action, createReducer, on } from '@ngrx/store';
import { fetchChatEmployees, addNofificationChatEmployee, addChatEmployee, changeChatEmployeeStatus, addNofificationChatEmployeeOnLoadTime, removeNofificationChatEmployee } from './chat-employee.action';
import { chatEmployee , ChatEmp } from './chat-employee.model';
import { FirebaseMessage } from '../chatNotification/chat-notification.model';



export const initialState: ChatEmp[] = []

export const chatEmployeeReducer = createReducer(
  initialState,
  on(fetchChatEmployees, (state, { allchatEmployee }) => {
    return allchatEmployee;
  }),
  on(addChatEmployee, (state, { chatEmployee }) => {
    return [...state, chatEmployee];
  }),
  on(changeChatEmployeeStatus, (state, { chatEmployeeId, status }) => {
    state.some((item: chatEmployee) => {
      if (item.participant.participantId == chatEmployeeId) {
        item.participant.status = status;
        return true;
      }
    });
    return state;
  }),
  on(addNofificationChatEmployee, (state, { notification }) => {
    state.some((item: chatEmployee) => {

      if (item.participant.participantId == notification.data.senderId) {
        if (item.nofitication) {
          let count  =  item.nofitication.count ;
          item.nofitication = notification
          item.nofitication.count =  count + 1 ;
        } else {
          item.nofitication = notification
          item.nofitication.count = 1;
        }
        return true;
      }
    });
    return state;
  }),
  on(addNofificationChatEmployeeOnLoadTime, (state, { notificationArr }) => {
   
    notificationArr.forEach((item: FirebaseMessage) => {
      state.some((emp: chatEmployee) => {
        if (emp.participant.participantId == item.data.senderId) {         
            emp.nofitication = item;
          return true;

        }


      
      });

    })
    return state;
  }),
  on(removeNofificationChatEmployee, (state, { roomId }) => {
    state.some((item: chatEmployee) => {

      if (item.roomId == roomId) {
        if (item.nofitication) {
          item.nofitication.data.content = '';
          item.nofitication.count = 0;
        }

        return true;
      }
    });
    return state;
  })

);

export function reducer(state: chatEmployee[], action: Action) {
  return chatEmployeeReducer(state, action);
}
