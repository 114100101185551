import { Action, createReducer, on } from '@ngrx/store';
import { updatemessage, makemessageRead, setEmptyOnMessageState ,fetchMessagesInBatch, fetchMessages } from './chat-message.action';
import { Message, ChatMessage } from './chat-message.model';



export const initialState: ChatMessage = {
    roomId: '',
    message: []
};

export const ChatmessageReducer = createReducer(
    initialState,
    on(fetchMessages, (state, { allMessage }) => {
        return allMessage;
    }),
    on(fetchMessagesInBatch, (state, { allMessage }) => {
        if(allMessage.roomId ==  state.roomId){
            return { roomId: state.roomId, message: [...allMessage.message, ...state.message] };
        }else {
            return allMessage;
        }
    }),
    on(updatemessage, (state, { message }) => {
        return { roomId: state.roomId, message: [...state.message, message] };
    }),
    on(makemessageRead, (state, { messageId }) => {
        state.message.some((item: Message) => {
            if (item.messageId == messageId) {
                item.status = 'read';
                return true;
            }
        });
        return { roomId: state.roomId, message: [...state.message] };
    }),
    on(setEmptyOnMessageState, (state) => {
        return { roomId: '', message: [] };
    })

);

export function reducer(state: ChatMessage, action: Action) {
    return ChatmessageReducer(state, action);
}
