import { createAction, props } from '@ngrx/store';
import  {  ChatMessage, Message  }   from './chat-message.model' ;
// fetch 


export const fetchMessages = createAction('[Data] fetch  message' , props<{ allMessage: ChatMessage }>());
export const fetchMessagesInBatch = createAction('[Data] fetch fetchMessagesInBatch message' , props<{ allMessage: ChatMessage }>());
export const updatemessage = createAction('[Data] update  message' , props<{ message: Message }>());
export const makemessageRead = createAction('[Data] read  message' , props<{ messageId: string  }>());
export const setEmptyOnMessageState =  createAction('[Data] set Messsge Empty ');

