// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: "fakebackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  // production: false,
  cognitoConfig: {
    mandatorySignIn: true,
    // region: 'us-east-1',
    // userPoolId: 'us-east-1_ZX6Tt5Klj',
    // userPoolWebClientId: '1kqn4veu46vkmmkr5q5t4b73ip',
    region: "ap-south-1",
    userPoolId: "ap-south-1_oEJS8i7gH",
    userPoolWebClientId: "2q6fissbelsg8ucbvda3b6k6jo",
    oauth: {
      domain: "https://studentsignin.auth.ap-south-1.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://localhost:4200/student/",
      responseType: "code",
    },
  },
  agentCognitoConfig: {
    // mandatorySignIn: true,
    // region: 'us-east-1',
    // userPoolId: 'us-east-1_LPEfaiH5x',
    // userPoolWebClientId: '59gqif2lhfavcrfj4vk9vt6bjh',
    // oauth: {
    // 	domain: '',
    // 	scope: [],
    // 	redirectSignIn: '',
    // 	redirectUriSignOut: '',
    // 	responseType: 'code',
    // },
    mandatorySignIn: true,
    region: "ap-south-1",
    userPoolId: "ap-south-1_iONwcyEVA",
    userPoolWebClientId: "1rmcp3bg5vja0sl40hmbm17rb8",
    oauth: {
      domain: "",
      scope: [],
      redirectSignIn: "",
      redirectUriSignOut: "",
      responseType: "code",
    },
  },

  // old Lambda server url
  // baseUrl: 'https://l3hwwchx12.execute-api.ap-south-1.amazonaws.com/Dev/',

  // new Node js url

  baseUrl: "https://l3hwwchx12.execute-api.ap-south-1.amazonaws.com/Dev/",
  // prod
  // baseUrlNodeJS: "https://visionway-prod-api.com/api/v1/",
  // baseUrlReporting: "https://reporting.visionway-prod-api.com/",
  // baseUrlCompany:"https://company.visionway-prod-api.com/",
  // baseUrlAutomation:"https://marketing.visionway-prod-api.com/",
  // baseUrlChat: "https://chat.visionway-prod-api.com/",
  // photoCaptureSockectBaseUrl :'https://qa.aiinterview.visionway-qa.com/ws/capture',
  // chatWithAi: "https://chatwithai.visionway-dev.com/query",
  // historyChatWithAi : "https://chatwithai.visionway-dev.com/chat/history/",
  // clearChatHistoryAi :"https://chatwithai.visionway-dev.com/chat/clear/",
  // qa
  baseUrlNodeJS: "https://dev.visionway-dev.com/api/v1/",
  baseUrlReporting: "https://dev.reporting.visionway-dev.com/",
  baseUrlCompany:"https://dev.company.visionway-dev.com/",
  baseUrlAutomation:"https://dev.marketing.visionway-dev.com/",
  baseUrlChat: "https://chat.dev.visionway-dev.com/",
  photoCaptureSockectBaseUrl :'https://qa.aiinterview.visionway-qa.com/ws/capture',
  chatWithAi: "https://chatwithai.visionway-dev.com/query",
  historyChatWithAi : "https://chatwithai.visionway-dev.com/chat/history/",
  clearChatHistoryAi :"https://chatwithai.visionway-dev.com/chat/clear/",
  basePersonalChatSocketUrl: "http://socket.visionway-prod-api.com/"  ,
  stripeKey:
    "pk_test_51MGciWSEO9Q4lrMsOeI6PcaUiurGm27QqseUZEzpjGGYvKsIB0iBWSDYX3SgLO8P6XZGDempcnc7GJ61cqEJvEh2003spFe5gl",

  // stripeKey:
  // 	'pk_test_51MGciWSEO9Q4lrMsOeI6PcaUiurGm27QqseUZEzpjGGYvKsIB0iBWSDYX3SgLO8P6XZGDempcnc7GJ61cqEJvEh2003spFe5gl',
  visionWayFee: 100,
  // // S3 configuration development QA
  // bucketName: 'visionwayqa-useast1-bucket-common',
  // accessKeyId: 'AKIA3GQUR3OY6SO33AN2',
  // secretAccessKey: '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',
  // region: 'us-east-1',

  // S3 configuration prod
  bucketName: "visionway-prodapsouth1-bucket-common",
  accessKeyId: "AKIAZO3MP5LLCUW4KVNR",
  // accessKeyId: 'AKIAZO3MP5LLAB76556Z',
  // secretAccessKey: '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',
  secretAccessKey: "74xViKyLvBhOqkSHa0GqBzahdmB3FUx1ziHlY52v",
  region: "ap-south-1",

  // firebase configuration for notification
  firebase: {
    apiKey: "AIzaSyCjitgfdz1EWAXmHABRdhG4qZlrgjwgf3o",
    authDomain: "vision-way.firebaseapp.com",
    projectId: "vision-way",
    storageBucket: "vision-way.appspot.com",
    messagingSenderId: "1016463848472",
    appId: "1:1016463848472:web:529d815ef6b41547983f6d",
    measurementId: "G-NZG72FSVP3",
    vapidKey:
      "BEM0oqXRWOXkqDukUjHgoHQNb9zVpKekUHX9Do0H98JnzxO3tdjdsaz15xBmxs0-QP3y9rm4RXn5fTDma3r3b-E",
  },

  // RazorPay test Credential
  razorPayCredentials: {
    Username: "rzp_test_EndWNnvzpae9Ov",
    Password: "eG0GQyl3YyVRWinnmri5M49h",
    url: "https://api.razorpay.com/v1/",
  },
  api:{
    crm:{
      nodeApiUrl:""
    },
    company:{
      nodeApiUrl:""
    },
    reporting:{
      nodeApiUrl:""
    },
    marketing:{
      nodeApiUrl:""
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
