import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { RootReducerState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { updateNotification } from 'src/app/store/chatNotification/chat-notification.action';
import { ToastrService } from 'ngx-toastr';
import { addNofificationChatEmployee } from 'src/app/store/chatEmployee/chat-employee.action';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  currentActiveRoomId: string = '';

  constructor(
    private afMessaging: AngularFireMessaging,
    private http: HttpClient,
    public store: Store<RootReducerState>,
    private _toasterService: ToastrService

  ) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'notification-data') {
          this.handleNotificationUpdate(event.data.payload);
        }
      });
    }
  }



  requestPermission(): Observable<any> {
    return new Observable((observer) => {
      this.afMessaging.requestToken.subscribe(
        (token) => {
          observer.next(token);
        },
        (error) => {
          console.error('Permission denied or error occurred:', error);
          observer.error(error);
        }
      );
    });
  }

  // registerTokenOnBackend(token: string): void {
  //   const apiUrl = 'https://your-backend-api.com/register-device';
  //   this.http.post(apiUrl, { token }).subscribe(
  //     (response) => {
  //       console.log('Device registered successfully:', response);
  //     },
  //     (error) => {
  //       console.error('Error registering device:', error);
  //     }
  //   );
  // }

  listenForMessages(): Observable<any> {
    return this.afMessaging.messages;
  }

  // listenForMessages(): Observable<any> {
  //   return new Observable((observer) => {
  //     this.afMessaging.messages.subscribe(
  //       (message) => {
  //         console.log('Foreground message received:', message);
  //         observer.next(message);
  //       },
  //       (error) => {
  //         console.error('Error while listening for messages:', error);
  //         observer.error(error);
  //       }
  //     );
  //   });
  // }

  updateRoomId(roomId: string): void {
    console.log("URM");
    this.currentActiveRoomId = roomId;
  }

  handleNotificationUpdate(payload: any) {
    try {
      // console.log('Notification Data Received: .....................', payload);
      if (this.currentActiveRoomId && this.currentActiveRoomId == payload.data.roomId) {
        console.log("CRA", this.currentActiveRoomId);
      } else {
        if (payload.data.contentType == 'image') {
          payload.data.content = 'sent you a image';
        }
        if (payload.data.contentType == 'file') {
          payload.data.content = 'sent you a file';
        }
        console.log("FCMRM" , payload);
        this.store.dispatch(updateNotification({ notification: payload }));
        this.store.dispatch(addNofificationChatEmployee({ notification: payload }));
        this._toasterService.info(
          payload.data.content,
          payload.data.firstName
        );
      }


    } catch (err) {
      console.log("error", err);
    }
  }
}
