import { createAction, props } from '@ngrx/store';
import  {  FirebaseMessage  }   from './chat-notification.model' ;
// fetch 



export const updateNotification = createAction('[Data] update  Notification' , props<{ notification: FirebaseMessage }>());
export const insertNotification = createAction('[Data] insert  Notification On load' , props<{ notification: FirebaseMessage[] }>());
export const makeNotificationRead = createAction('[Data] remove  Notification' , props<{ roomId: string  }>());

