import { Action, createReducer, on } from '@ngrx/store';
import { fetchChatEmployees, addNofificationChatEmployee, addChatEmployee, changeChatEmployeeStatus, addNofificationChatEmployeeOnLoadTime, removeNofificationChatEmployee, addMyLastChatAndTime } from './chat-employee.action';
import { chatEmployee, ChatEmp } from './chat-employee.model';
import { FirebaseMessage } from '../chatNotification/chat-notification.model';
import moment from 'moment';



export const initialState: ChatEmp[] = []

export const chatEmployeeReducer = createReducer(
  initialState,
  on(fetchChatEmployees, (state, { allchatEmployee }) => {
    
    let chatEmp = allchatEmployee.filter((emp) => {
      if (emp.participant && emp.participant.firstName) {
        if (emp.lastMessageTime) {
          if (emp.lastMessageType == 'text') {
            if (emp.lastMessage.length > 53) {
              emp.lastMessage = emp.lastMessage.slice(0, 48)
            }
          } else {
            if (emp.lastMessageType == 'image') {
              emp.lastMessage = 'image ...';
            }
            else if (emp.lastMessageType == 'link') {
              emp.lastMessage = 'link ...';
            }
            else if (emp.lastMessageType == 'emoji') {
            }
            else {
              emp.lastMessage = 'file ...'
            }
          }

          emp.lastMessageTime = emp.lastMessageTime
            ? moment(emp.lastMessageTime).isSame(moment(), 'day')
              ? moment(emp.lastMessageTime).format('hh:mm A')
              : moment(emp.lastMessageTime).format('DD/MM/YYYY')
            : '';
        }
        return true;
      }
    });
    
    
    return chatEmp;
  }),
  on(addChatEmployee, (state, { chatEmployee }) => {
    if (chatEmployee.participant && chatEmployee.participant.firstName) {
      return [...state, chatEmployee];
    } else {
      return state;
    }
  }),
  on(changeChatEmployeeStatus, (state, { chatEmployeeId, status }) => {
    state.some((item: chatEmployee) => {
      if (item.participant.participantId == chatEmployeeId) {
        let participant = { ...item.participant, status: status }
        item.participant = participant;
        return true;
      }
    });
    return state;
  }),
  on(addNofificationChatEmployee, (state, { notification }) => {
    if (notification.data.type && notification.data.type == 'group') {
      return state;
    }
    let index = 0;
    state.some((item: chatEmployee, i: number) => {

      if (item.participant.participantId == notification.data.senderId) {
        let count = (item.nofitication && item.nofitication.count) ? item.nofitication.count + 1 : 1;
        item.nofitication = notification
        item.nofitication.count = count;
        index = i;
        item.lastMessageTime = moment().format('hh:mm A');
        item.lastMessage = notification.data.content;
        item.lastMessageType = notification.data.contentType;
        if (item.lastMessageType == 'text') {
          if (item.lastMessage.length > 53) {
            item.lastMessage = item.lastMessage.slice(0, 48)
          }
        } else {
          if (item.lastMessageType == 'image') {
            item.lastMessage = 'image ...';
          }
          else if (item.lastMessageType == 'link') {
            item.lastMessage = 'link ...';
          }
          else if (item.lastMessageType == 'emoji') {
          }
          else {
            item.lastMessage = 'file ...'
          }
        }
        return true;
      }
    });
    if (index) {
      return [...state.splice(index, 1), ...state];
    }

    return state;
  }),
  on(addNofificationChatEmployeeOnLoadTime, (state, { notificationArr }) => {

    notificationArr.forEach((item: FirebaseMessage) => {
      let index = 0;
      state.some((emp: chatEmployee, i) => {
        if (emp.roomId == item.data.roomId) {
          emp.nofitication = item;
          index = i;
          return true;
        }
      });
      if (index) {
        state = [...state.splice(index, 1), ...state];
      }
    });

    return state;
  }),
  on(removeNofificationChatEmployee, (state, { roomId }) => {
    state.some((item: chatEmployee) => {

      if (item.roomId == roomId) {
        if (item.nofitication) {
          item.nofitication.data.content = '';
          item.nofitication.count = 0;
        }

        return true;
      }
    });
    return state;
  }),


  on(addMyLastChatAndTime, (state, { roomId, content, contentType }) => {
    state.some((item: chatEmployee) => {

      if (item.roomId == roomId) {
        item.lastMessageTime = moment().format('hh:mm A');
        item.lastMessage = content;
        item.lastMessageType = contentType;
        if (item.lastMessageType == 'text') {
          if (item.lastMessage.length > 53) {
            item.lastMessage = item.lastMessage.slice(0, 48)
          }
        } else {
          if (item.lastMessageType == 'image') {
            item.lastMessage = 'image ...';
          }
          else if (item.lastMessageType == 'link') {
            item.lastMessage = 'link ...';
          }
          else if (item.lastMessageType == 'emoji') {
          }
          else {
            item.lastMessage = 'file ...'
          }
        }

        return true;
      }
    });
    return state;
  })

);

export function reducer(state: chatEmployee[], action: Action) {
  return chatEmployeeReducer(state, action);
}
